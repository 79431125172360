import Base from '../common/models/Base';

export default class Travel extends Base {
  constructor() {
    super('travel', {
      travel: {
        type: 'radio',
        fieldConfig: {
          title: 'Путешествовали ли вы за последний год на поезде или на самолете?',
          items: [
            {
              label: 'Да',
              value: true,
            },
            {
              label: 'Нет',
              value: false,
            },
          ],
          border: true,
          column: true,
          defaultValue: false,
        },
      },
      train: {
        type: 'input',
        fieldConfig: {
          title: 'Поезд',
          description: 'км',
          type: 'number',
          mask: '#####',
          icon: 'train',
        },
      },
      plane: {
        type: 'input',
        fieldConfig: {
          title: 'Самолет',
          description: 'км',
          type: 'number',
          mask: '#####',
          icon: 'airplane',
        },
      },
    });
  }
}
