<template>
  <div class="calc-view">
    <main-container />
  </div>
</template>

<script>
import MainContainer from '../components/MainContainer';

export default {
  name: 'Calc',
  components: {
    MainContainer,
  },
};
</script>

<style lang="sass">
  .calc-view
</style>
