import Base from '../common/models/Base';

export default class Transport extends Base {
  svgPrefix = 'parkingfinal_svg__';

  bodyMap = {};

  constructor() {
    super('transport', {
      haveCar: {
        type: 'radio',
        fieldConfig: {
          title: 'Есть ли у вас личный автомобиль:',
          items: [
            {
              label: 'Да',
              value: true,
            },
            {
              label: 'Нет',
              value: false,
            },
          ],
          defaultValue: false,
          border: true,
        },
      },
      fuel: {
        type: 'card',
        fieldConfig: {
          title: 'Какой вид топлива потребляет авто:',
          items: [
            {
              label: 'Бензин',
              value: 'petrol',
              icon: 'petrol',
            },
            {
              label: 'Дизель',
              value: 'diesel',
              icon: 'diesel',
            },
            {
              label: 'Электро',
              value: 'electro',
              icon: 'electro',
            },
          ],
          defaultValue: 'petrol',
          border: true,
        },
      },
      body: {
        type: 'card',
        fieldConfig: {
          title: 'Насколько большая машина:',
          items: [
            {
              label: 'Легковая',
              value: 'common',
              icon: 'passengercar',
            },
            {
              label: 'Универсал',
              value: 'universal',
              icon: 'stationwagon',
            },
            {
              label: 'Внедорожник',
              value: 'suv',
              icon: 'suv',
            },
            {
              label: 'Минивен/Микроавтобус',
              value: 'minivan',
              icon: 'minivan',
            },
          ],
          defaultValue: 'common',
          border: true,
        },
      },
      yearOfIssue: {
        type: 'slider',
        fieldConfig: {
          title: 'Год выпуска автомобиля',
          type: 'number',
          min: 1990,
          max: new Date().getFullYear(),
          unit: 'год',
          step: 10,
          border: true,
        },
      },
      milleage: {
        type: 'input',
        fieldConfig: {
          title: 'Пробег автомобиля за год',
          type: 'number',
          description: 'км',
          border: true,
          mask: '#####',
        },
      },
    },
    {
      haveCar: (model) => {
        const body = model.getValue('body');
        const fuel = model.getValue('fuel');
        this.bodyMap[body].classList.toggle('disable');
        this.bodyMap[body].querySelectorAll(`g[id^="parkingfinal_svg__${fuel}"]`)[0].classList.toggle('disable');
      },
      fuel: (model, value, prevValue) => {
        const haveCar = model.getValue('haveCar');
        const body = model.getValue('body');
        if (!haveCar || !body) {
          return;
        }
        if (prevValue) {
          this.bodyMap[body].querySelectorAll(`g[id^="parkingfinal_svg__${prevValue}"]`)[0].classList.toggle('disable');
        }
        this.bodyMap[body].querySelectorAll(`g[id^="parkingfinal_svg__${value}"]`)[0].classList.toggle('disable');
      },
      body: (model, value, prevValue) => {
        const haveCar = model.getValue('haveCar');
        if (!haveCar || !value) {
          return;
        }
        const fuel = model.getValue('fuel');
        if (prevValue) {
          this.bodyMap[prevValue].classList.toggle('disable');
          this.bodyMap[prevValue].querySelectorAll(`g[id^="parkingfinal_svg__${fuel}"]`)[0].classList.toggle('disable');
        }
        this.bodyMap[value].classList.toggle('disable');
        this.bodyMap[value].querySelectorAll(`g[id^="parkingfinal_svg__${fuel}"]`)[0].classList.toggle('disable');
      },
    });
    this.bodyMap = {
      minivan: document.querySelector(`#${this.svgPrefix}minivan`),
      common: document.querySelector(`#${this.svgPrefix}passenger`),
      suv: document.querySelector(`#${this.svgPrefix}suv`),
      universal: document.querySelector(`#${this.svgPrefix}universal`),
    };
  }
}
