import Base from '../common/models/Base';
import vuetify from '../plugins/vuetify';

export default class House extends Base {
  svgPrefix = 'finalapartament_svg__';

  order = [
    'child1',
    'woman1',
    'grandpa',
    'man1',
  ];

  constructor() {
    super('House', {
      houseArea: {
        type: 'input',
        fieldConfig: {
          title: 'Площадь жилья:',
          description: 'м²',
          type: 'number',
          min: 0,
          max: 200,
          border: true,
        },
      },
      people: {
        type: 'slider',
        fieldConfig: {
          title: 'Сколько человек живет в доме:',
          min: 1,
          max: 10,
          border: true,
          type: 'number',
          mask: '##',
          narrow: true,
        },
      },
      animal: {
        type: 'radio',
        fieldConfig: {
          title: 'Есть ли животное в доме:',
          defaultValue: false,
          column: vuetify.framework.breakpoint.mobile,
          items: [
            {
              label: 'Да',
              value: true,
            },
            {
              label: 'Нет',
              value: false,
            },
          ],
          border: true,
        },
      },
      cat: {
        type: 'input',
        fieldConfig: {
          type: 'number',
        },
      },
      dog: {
        type: 'input',
        fieldConfig: {
          type: 'number',
        },
      },
      hamster: {
        type: 'input',
        fieldConfig: {
          type: 'number',
        },
      },
      electricity: {
        type: 'slider',
        fieldConfig: {
          title: 'Потребление электричества',
          type: 'number',
          min: 0,
          max: 400,
          unit: 'кВт*ч',
          step: 100,
          narrow: true,
        },
      },
      gas: {
        type: 'slider',
        fieldConfig: {
          title: 'Потребление газа',
          min: 0,
          max: 30,
          unit: 'м³',
          step: 10,
          type: 'number',
          narrow: true,
        },
      },
      water: {
        type: 'slider',
        fieldConfig: {
          title: 'Потребление воды',
          min: 0,
          max: 50,
          unit: 'м³',
          step: 15,
          narrow: true,
        },
      },
      heating: {
        type: 'card',
        fieldConfig: {
          title: 'Тип отопления в доме:',
          items: [
            {
              label: 'Газ',
              value: 'gas',
              icon: 'gasheating',
            },
            {
              label: 'Центральная система отопления',
              value: 'centralHeating',
              icon: 'centralheating',
            },
          ],
          defaultValue: 'gas',
          width: 'auto',
          alignItems: 'center',
          border: true,
        },
      },
      additional: {
        type: 'card',
        fieldConfig: {
          title: 'Что есть в вашем доме:',
          items: [
            {
              label: 'Плита газовая',
              value: 'gasStove',
              icon: 'gastove',
            },
            {
              label: 'Плита электрическая',
              value: 'electricStove',
              icon: 'elstove',
            },
          ],
          defaultValue: 'gasStove',

          width: 'auto',
          alignItems: 'center',
        },
      },
      boiler: {
        type: 'radio',
        fieldConfig: {
          title: 'Водонагреватель:',
          column: vuetify.framework.breakpoint.mobile,
          items: [
            {
              label: 'Да',
              value: true,
            },
            {
              label: 'Нет',
              value: false,
            },
          ],
          defaultValue: false,
        },
      },
    }, {
      animal: (model, value) => {
        const dog = document.querySelector(`#${this.svgPrefix}dog`);
        const cat = document.querySelector(`#${this.svgPrefix}cat`);
        if (!value) {
          dog.classList.add('disable');
          cat.classList.add('disable');
          return;
        }
        if (model.getValue('dog')) {
          dog.classList.remove('disable');
        }
        if (model.getValue('cat')) {
          cat.classList.remove('disable');
        }
      },
      boiler: () => {
        const boilerSvg = document.querySelector(`#${this.svgPrefix}boiler`);
        boilerSvg.classList.toggle('disable');
      },
      additional: (_, value) => {
        if (value) {
          const gas = document.querySelector(`#${this.svgPrefix}gas`);
          const electro = document.querySelector(`#${this.svgPrefix}electro`);
          gas.classList.toggle('disable');
          electro.classList.toggle('disable');
        }
      },
      people: (_, value) => {
        const people = document.querySelector(`#${this.svgPrefix}people`) || {};
        if (!people || !(people || {}).children) {
          return;
        }
        const arrChildren = [];
        const peoples = [...people.children];
        peoples.forEach((item) => this.onlyPeople(item.id)
          && this.order
            .some(((orderItem) => new RegExp(orderItem).test(item.id)))
          && arrChildren.push(item));
        peoples.forEach((item) => this.onlyPeople(item.id) && this.order
          .every(((orderItem) => !new RegExp(orderItem).test(item.id))) && arrChildren.push(item));
        arrChildren.forEach((item, index) => {
          if (!item.classList) {
            return;
          }
          if (index < value) {
            item.classList.remove('disable');
          } else {
            item.classList.add('disable');
          }
        });
      },
      dog: (_, value) => {
        const dog = document.querySelector(`#${this.svgPrefix}dog`);
        if (value) {
          dog.classList.remove('disable');
        } else {
          dog.classList.add('disable');
        }
      },
      cat: (_, value) => {
        const cat = document.querySelector(`#${this.svgPrefix}cat`);
        if (value) {
          cat.classList.remove('disable');
        } else {
          cat.classList.add('disable');
        }
      },
    });
  }

  onlyPeople = (id) => !/cat|dog/.test(id)
}
