<template>
  <div :class="['carbon-input', { 'set-border': border }]">
    <div class="carbon-input__title">
      <span v-text="title" />
    </div>
    <div class="carbon-input__contain">
      <div class="carbon-input__contain__input">
        <v-text-field
          v-mask="mask"
          :name="type + title"
          dark
          hide-details
          :value="value"
          @input="input"
        />
      </div>
      <div v-if="description" class="carbon-input__contain__description">
        <span v-text="description" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CarbonInput',
  props: {
    title: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
    description: {
      type: String,
    },
    type: {
      type: String,
    },
    min: {
      type: [Number],
    },
    max: {
      type: [Number],
    },
    border: {
      type: Boolean,
    },
    mask: {
      type: String,
      default: () => '####',
    },
  },
  data: () => ({
    prevValue: null,
  }),
  computed: {
    isNumber() {
      return this.type === 'number';
    },
  },
  methods: {
    input(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="sass">
  .carbon-input
    display: flex
    justify-content: space-between
    font-style: normal
    font-weight: normal
    font-size: 22px
    line-height: 1.5em
    text-align: center
    color: #FFFFFF
    margin-bottom: 25px
    &__title
      width: auto
    &__contain
      display: flex
      margin: 0 10px
      justify-content: flex-end
      max-width: 150px
      &__input
        .v-input
          padding-top: 0
          &__control
            font-size: 22px
            .v-input__slot::before
              border-color: #4caf50 !important
            input
              text-align: center
      &__description
        margin-left: 10px
        min-width: 34px
  @media only screen and (max-width: 640px)
    .carbon-input
      font-size: 18px
      &__contain
        max-width: 110px
        &__input
          min-width: 50px
</style>
