<template>
  <v-container
    class="main-container"
  >
    <component
      v-for="(sections, i) in getSections"
      :is="sections.background ? sections.background : 'base-icon'"
      :name="sections.backgroundSvg"
      :class="['cover', { disable: !currentElem(i) }]"
      v-bind="bindCover"
      :key="i"
    />
    <v-carousel
      ref="carousel"
      v-model="courusel"
      :show-arrows="false"
      touchless
      hide-delimiters
      progress
      progress-color="#59A040"
      height="100%"
      class="carousel"
      :style="{ 'position': tabletAndMobile ? 'fixed' : 'relative' }"
    >
      <v-carousel-item
          v-for="(sections, i) in getSections" class="section"
          :style="{ 'height': tabletAndMobile ? 'calc(100% - 50px)' : '100%' }"
          :key="i"
      >
        <v-sheet
          tile
          height="100%"
        >
          <div class="section__head">
            <span v-if="sections.head" class="head" v-text="sections.head" />
            <span v-if="sections.description" class="description" v-text="sections.description" />
          </div>
          <v-divider class="green-divider" />
          <div class="section__body" :style="{ 'max-height': computedHeight }">
            <div class="section__body__component">
              <vue-scroll :ops="ops">
                <component :is="sections.component" class="component" @click="slide" />
              </vue-scroll>
            </div>
          </div>
          <v-divider class="green-divider mb-3" />
          <div class="section__action">
            <div class="section__action--left"  v-show="visiblePrev">
              <carbon-button icon="mdi-arrow-left" @click="slide('prev')" />
            </div>
            <div :class="['section__action--right', { center: !visiblePrev }] ">
              <carbon-button :text="textNextSlideBtn" @click="slide('next')" />
            </div>
          </div>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import CarbonButton from '@components/CarbonButton';
import House from './slides/House';
import Transport from './slides/Transport';
import Logistics from './slides/Logistics';
import Food from './slides/Food';
import Travel from './slides/Travel';

export default {
  name: 'MainContainer',
  components: {
    Logistics,
    House,
    Transport,
    Travel,
    TravelSvg: () => import('./graphics/Travel'),
    CarbonButton,
    Food,
  },
  data: () => ({
    courusel: 0,
    ops: {
      bar: {
        background: '#59A040',
        minSize: 0.1,
        keepShow: true,
      },
      scrollPanel: {
        scrollingX: false,
      },
    },
    windowHeight: window.innerHeight,
    loading: false,
  }),
  computed: {
    ...mapGetters(['getSections']),
    visiblePrev() {
      return this.courusel !== 0;
    },
    endCourusel() {
      return !(this.courusel !== this.getSections.length - 1);
    },
    textNextSlideBtn() {
      return this.endCourusel ? 'Рассчитать' : 'Далее';
    },
    viewBox() {
      const { height, width } = this.$vuetify.breakpoint;
      return `0 0 ${width} ${height}`;
    },
    tabletAndMobile() {
      return true;
    },
    computedHeight() {
      if (!this.$vuetify.breakpoint.mobile) {
        return `${this.windowHeight - 260}px`;
      }
      return `${this.windowHeight - 200}px`;
    },
    bindCover() {
      if (this.$vuetify.breakpoint.width > 640) {
        return { preserveAspectRatio: 'none' };
      }

      return { viewBox: this.viewBox };
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });
  },
  methods: {
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    slide(side) {
      if (this.endCourusel && side === 'next') {
        this.$store.dispatch('calc');
        return;
      }
      this.$refs.carousel[side]();
    },
    currentElem(index) {
      return this.courusel === index;
    },
  },
};
</script>

<style lang="sass">
  .main-container
    .cover
      width: 100vw
      height: calc(100vh - 50px)
      transition: opacity 1.5s ease-out
      opacity: 1
      z-index: 0
      position: absolute
      top: 0
      left: 0
      g
        transition: opacity 1.5s ease-out
    &.container
      margin-left: 10px
      padding-top: 0
      height: 100%
    .green-divider
      background: #59A040
      border-radius: 10px 10px 0px 0px
      border-width: 1px
    .v-progress-linear
      top: 25px
      position: absolute
    .carousel
      width: 40vw
      min-width: 480px
    .section
      padding: 25px 0 10px
      .v-sheet
        display: flex
        flex-direction: column
        padding: 20px
        background-color: rgba(63, 63, 60, 0.8)
      &__head
        display: flex
        justify-content: center
        padding: 0 0 15px 0
        flex-direction: column
      &__body
        max-height: calc(100vh - 260px)
        &__component
          overflow: hidden
          height: 100%
          .__view
            padding-bottom: 25px
            .component
              width: 100%
              padding: 10px 20px 0
      &__action
        display: flex
        justify-content: center
        margin-top: auto
        &--left
          flex: 1 1 30%
        &--right
          flex: 1 1 50%
          &.center
            flex: none
  @media only screen and (max-width: 810px)
    .main-container
      .cover
        widht: auto
        height: auto
      .v-progress-linear
        top: 0
      &.container
        margin-left: 0
        padding: 0
      .carousel
        width: 100%
        min-width: unset
      .section
        padding: 0
        &__head
          width: 100%
          padding: 0 5px 0px 5px
        &__body
          width: 100%
        &__action
          width: 100%
  @media only screen and (max-width: 640px)
    .main-container
      .section
        &__head
          width: 100vw
          margin-bottom: 8px
          .head
            font-size: 27px
        &__body
          width: 100vw
          &__component
            .__view
              .component
                padding: 5px 10px 0
        &__action
          width: 100vw
        .v-sheet
          padding-left: 5px
          padding-right: 5px
          padding-top: 10px
          //grid-template-rows: 10vh 1vh 72vh 1vh
</style>
