<template>
  <div class="house">
    <carbon-input
      v-bind="model.getInputProps('houseArea')"
      @input="(e) => model.setValue('houseArea', e)"
    />
    <carbon-slider
      v-bind="model.getProps('people')"
      @input="(e) => model.setValue('people', e)"
    />
    <carbon-radio
      v-bind="model.getRadioProps('animal')"
      @change="(e) => model.setValue('animal', e)"
    >
      <div :class="{ disable: !this.animal }" class="house__animals">
        <div>
          <v-icon v-text="'mdi-cat'" x-large color="white" />
          <carbon-input
            v-bind="model.getInputProps('cat')"
            @input="(e) => model.setValue('cat', e)"
          />
        </div>
        <div>
          <v-icon v-text="'mdi-dog'" x-large color="white" />
          <carbon-input
            v-bind="model.getInputProps('dog')"
            @input="(e) => model.setValue('dog', e)"
          />
        </div>
        <div>
          <v-icon v-text="'mdi-rabbit'" x-large color="white" />
          <carbon-input
            v-bind="model.getInputProps('hamster')"
            @input="(e) => model.setValue('hamster', e)"
          />
        </div>
      </div>
    </carbon-radio>
    <div class="set-border house__sliders">
      <carbon-slider
        v-bind="model.getProps('electricity')"
        @input="(e) => model.setValue('electricity', e)"
      />
      <carbon-slider
        v-bind="model.getProps('gas')"
        @input="(e) => model.setValue('gas', e)"
      />
      <carbon-slider
        v-bind="model.getProps('water')"
        @input="(e) => model.setValue('water', e)"
      />
    </div>
    <carbon-radio-card
      v-bind="model.getProps('heating')"
      @change="(e) => model.setValue('heating', e)"
    />
    <div class="set-border house__boiler">
      <carbon-radio-card
        v-bind="model.getProps('additional')"
        @change="(e) => model.setValue('additional', e)"
      />
      <carbon-radio
        v-bind="model.getProps('boiler')"
        @change="(e) => model.setValue('boiler', e)"
      />
    </div>
  </div>
</template>

<script>
import CarbonInput from '@components/CarbonInput';
import CarbonRadio from '@components/CarbonRadio';
import CarbonSlider from '@components/CarbonSlider';
import CarbonRadioCard from '@components/CarbonRadioCard';
import House from '../../models/House';

export default {
  name: 'House',
  components: {
    CarbonInput,
    CarbonRadio,
    CarbonSlider,
    CarbonRadioCard,
  },
  data: () => ({
    house: 0,
    people: 0,
  }),
  computed: {
    model() {
      return this.$store.state.houseModel;
    },
    animal() {
      return this.model.getValue('animal');
    },
  },
  created() {
    this.$store.commit('SET_MODEL_HOUSE', new House());
  },
  methods: {
    change(value, item) {
      this[item] = value;
    },
  },
};
</script>

<style lang="sass">
  .house
    display: flex
    flex-direction: column
    &__animals
      display: flex
      justify-content: center
      transition: opacity 1s ease-out
      opacity: 1
      flex-wrap: wrap
      > div
        display: flex
        max-width: 180px
        > i
          font-size: 64px !important
    &__sliders
      display: flex
      flex-direction: column
      margin-bottom: 25px
  @media only screen and (max-width: 640px)
    .house
      &__animals
        align-items: center
        flex-direction: column
        > div
          > i
            font-size: 50px !important
</style>
