import Base from '../common/models/Base';

export default class Food extends Base {
  constructor() {
    super('Food', {
      ration: {
        type: 'card',
        fieldConfig: {
          title: 'Какой у вас рацион питания:',
          items: [
            {
              label: 'Сбалансированный',
              value: 'balance',
              icon: 'balance',
            },
            {
              label: 'Веган',
              value: 'vegan',
              icon: 'vegan',
            },
            {
              label: 'Лактоововеган',
              value: 'lactVegan',
              icon: 'l-o-veg',
            },
          ],
          defaultValue: 'balance',
          border: true,
        },
      },
      meat: {
        type: 'slider',
        fieldConfig: {
          title: 'Как часто вы едите мясо',
          min: 1,
          max: 7,
          underMin: false,
          border: true,
          step: 1,
          unit: 'раз',
          narrow: true,
        },
      },
    });
  }
}
