import Vue from 'vue';

export default class Base {
  setModelName(name) {
    Vue.set(this, 'modelName', name);

    return this;
  }

  getModelName() {
    return this.modelName;
  }

  getError(fieldName, index) {
    if (this.getFieldConfig(fieldName)
      && this.getFieldConfig(fieldName).multiple
      && index !== null
    ) {
      return this.errors[fieldName][index];
    }

    return this.errors[fieldName];
  }

  getErrors() {
    return Object.entries(this.errors).map((it) => (it[1] ? it[0] : null)).filter((it) => it);
  }

  addError(fieldName, errorText, index) {
    if (this.getFieldConfig(fieldName)
      && this.getFieldConfig(fieldName).multiple
      && index !== null
    ) {
      Vue.set(this.errors[fieldName], index, errorText);
      return;
    }

    Vue.set(this.errors, fieldName, errorText);
  }

  clearError(fieldName, index) {
    if (this.getFieldConfig(fieldName)
      && this.getFieldConfig(fieldName).multiple
      && index !== null
    ) {
      Vue.set(this.errors[fieldName], index, null);
      return;
    }

    Vue.set(this.errors, fieldName, null);
  }

  getName(fieldName) {
    return this.map[fieldName].name;
  }

  setCheckboxItems(fieldName, items) {
    Vue.set(this.checkboxes, fieldName, items);
    if (this.values && !(fieldName in this.values)) {
      Vue.set(this.values, fieldName, []);
    }
  }

  setSlider(fieldName, value) {
    Vue.set(this.sliders, fieldName, value);
  }

  setInput(fieldName, value) {
    Vue.set(this.inputs, fieldName, value);
  }

  setRadioItems(fieldName, items) {
    Vue.set(this.radios, fieldName, items);
    if (this.values && !(fieldName in this.values)) {
      Vue.set(this.values, fieldName, '');
    }
  }

  setCardItems(fieldName, items) {
    Vue.set(this.cards, fieldName, items);
    if (this.values && !(fieldName in this.values)) {
      Vue.set(this.values, fieldName, '');
    }
  }

  clearCheckboxItems() {
    Vue.set(this, 'checkboxes', {});
  }

  clearRadioItems() {
    Vue.set(this, 'radios', {});
  }

  setAutocompleteItems(fieldName, items, index = null, ignoreSideEffects = true) {
    if (this.getFieldConfig(fieldName)
      && this.getFieldConfig(fieldName).multiple
      && index !== null
    ) {
      Vue.set(this.autocompletes[fieldName], index, items);
      if (!ignoreSideEffects) {
        this.checkSideEffects(fieldName);
      }
      return;
    }

    Vue.set(this.autocompletes, fieldName, items);
    if (!ignoreSideEffects) {
      this.checkSideEffects(fieldName);
    }
  }

  getAutocompleteItems(fieldName, index = null) {
    if (this.getFieldConfig(fieldName)
      && this.getFieldConfig(fieldName).multiple
      && index !== null
    ) {
      return this.autocompletes[fieldName][index];
    }

    return this.autocompletes[fieldName];
  }

  setSelectItems(fieldName, items, index = null, ignoreSideEffects = true) {
    const config = this.getFieldConfig(fieldName);
    if (config.firstElement) {
      items.unshift(config.firstElement);
    }
    if (config
      && config.multiple
      && index !== null
    ) {
      Vue.set(this.selects[fieldName], index, items);
      if (!ignoreSideEffects) {
        this.checkSideEffects(fieldName);
      }
      return;
    }

    Vue.set(this.selects, fieldName, items);
    if (!ignoreSideEffects) {
      this.checkSideEffects(fieldName);
    }
  }

  getSelectItems(fieldName, index = null) {
    if (this.getFieldConfig(fieldName)
      && this.getFieldConfig(fieldName).multiple
      && index !== null
    ) {
      return this.selects[fieldName][index];
    }

    return this.selects[fieldName];
  }

  getCardItems(fieldName) {
    return this.cards[fieldName] || [];
  }

  getCheckboxItems(fieldName) {
    return this.checkboxes[fieldName] || [];
  }

  getRadioItems(fieldName) {
    return this.radios[fieldName] || [];
  }

  getAvailableCheckboxes() {
    return Object.keys(this.checkboxes);
  }

  getAvailableRadios() {
    return Object.keys(this.radios);
  }

  validate(step = null) {
    const validationResult = Object.entries(this.values).filter((pair) => {
      const { required, step: stepByField, validate } = this.getFieldConfig(pair[0]);
      if (step && ((stepByField && stepByField !== step) || !stepByField)) {
        return false;
      }
      if (!(pair[1] instanceof Base) && !(pair[1] instanceof Array)) {
        let subValidation = false;
        if (validate instanceof Function) {
          subValidation = validate(this);
        }

        if (!required) {
          return subValidation;
        }

        if (required instanceof Function && !required(this)) {
          return subValidation;
        }
      }

      if (pair[1] instanceof Base) {
        return pair[1].validate();
      }
      if (pair[1] instanceof Array) {
        if (!required) {
          return false;
        }

        if (pair[1].length === 0) {
          this.addError(pair[0], `Поле '${this.getName(pair[0])}' обязательно для заполнения`);
          return true;
        }

        return pair[1].map((it, index) => {
          if (it instanceof Base) {
            return it.validate();
          }
          if (!it) {
            this.addError(pair[0], `Поле '${this.getName(pair[0])}' обязательно для заполнения`, index);
            return true;
          }

          return false;
        }).some((it) => it);
      }

      if (!pair[1]) {
        this.addError(pair[0], `Поле '${this.getName(pair[0])}' обязательно для заполнения`, null);
        return true;
      }
      return false;
    });
    return validationResult.length !== 0;
  }

  checkFields() {
    const validationResult = Object.entries(this.values).filter((pair) => {
      const { required } = this.getFieldConfig(pair[0]);
      if (!(pair[1] instanceof Base) && !(pair[1] instanceof Array)) {
        if (!required) {
          return false;
        }

        if (required instanceof Function && !required(this)) {
          return false;
        }
      }

      if (pair[1] instanceof Base) {
        return pair[1].checkFields();
      }

      if (pair[1] instanceof Array) {
        if (!required) {
          return false;
        }

        if (pair[1].length === 0) {
          return true;
        }

        return pair[1].map((it) => {
          if (it instanceof Base) {
            return it.checkFields();
          }

          return !it;
        }).some((it) => it);
      }

      if (!pair[1]) {
        return true;
      }

      return false;
    });
    return validationResult.length !== 0;
  }

  getValues() {
    const result = {};

    Object.entries(this.values).forEach((it) => {
      if (it[1] instanceof Base) {
        result[it[0]] = it[1].getValues();
        return;
      }
      if (it[1] instanceof Array) {
        result[it[0]] = it[1].map((arrayItem) => {
          if (arrayItem instanceof Base) {
            const arrItValues = arrayItem.getValues();
            const notEmptyValues = Object.values(arrItValues)
              .filter((arrItVal) => (
                arrItVal !== undefined
                && arrItVal !== null
                && !(arrItVal instanceof Array)
              ) || (arrItVal && arrItVal.length));

            if (notEmptyValues.length === 0) {
              return null;
            }

            return arrItValues;
          }
          return arrayItem;
        }).filter((arrIt) => arrIt);
        return;
      }
      // eslint-disable-next-line prefer-destructuring
      result[it[0]] = it[1];
    });

    return result;
  }

  getValue(fieldName, index = null) {
    if (
      (this.map[fieldName].multiple
        || (this.getFieldConfig(fieldName) && this.getFieldConfig(fieldName).multiple))
      && index !== null
    ) {
      return this.values[fieldName][index];
    }

    return this.values[fieldName];
  }

  setValueBySideEffect(fieldName, value) {
    Vue.set(this.values, fieldName, value);
    this.clearError(fieldName);
  }

  setValue(fieldName, value, index = null) {
    if (this.getFieldConfig(fieldName)
      && this.getFieldConfig(fieldName).multiple
      && index !== null
    ) {
      Vue.set(this.values[fieldName], index, value);
      this.clearError(fieldName, index);
      return;
    }
    const prevValue = this.getValue(fieldName);
    Vue.set(this.values, fieldName, value);
    this.clearError(fieldName);
    this.checkSideEffects(fieldName, prevValue);
  }

  isVisible(fieldName) {
    return !this.map[fieldName].hidden;
  }

  getFieldConfig(fieldName) {
    const fieldConfig = { ...this.map[fieldName].fieldConfig };
    if (fieldConfig.required instanceof Function) {
      fieldConfig.required = fieldConfig.required(this);
    }
    return fieldConfig;
  }

  setFieldConfigParam(fieldName, paramName, value) {
    if (!this.map[fieldName] || !this.map[fieldName].fieldConfig) {
      return;
    }

    Vue.set(this.map[fieldName].fieldConfig, paramName, value);
  }

  getRequestConfig(fieldName) {
    return this.map[fieldName].requestConfig;
  }

  initValues() {
    this.values = {};
    Object.keys(this.map).forEach((it) => {
      if (this.map[it].validationIgnore || !this.isVisible(it)) {
        return;
      }
      let val = null;
      if (this.map[it].model) {
        // eslint-disable-next-line new-cap
        val = new this.map[it].model(this.map[it].fieldConfig, this.map[it].sideEffects);
      }
      if (this.map[it].type === 'radio') {
        const { defaultValue } = this.getFieldConfig(it);
        if (defaultValue !== undefined) {
          val = defaultValue;
        } else {
          val = (this.getFieldConfig(it).items || []).find((radioItem) => radioItem.value);
        }
      }
      if ((this.getFieldConfig(it) && this.getFieldConfig(it).multiple) || this.map[it].multiple) {
        val = [val];
      }
      this.values[it] = val;
    });

    return this;
  }

  initErrors() {
    Vue.set(this, 'errors', {});
    Object.keys(this.map).forEach((it) => {
      if (this.map[it].validationIgnore || !this.isVisible(it)) {
        return;
      }
      let val = null;
      if (this.getFieldConfig(it) && this.getFieldConfig(it).multiple) {
        val = [null];
      }
      this.addError(it, val, null);
    });

    return this;
  }

  initSelects() {
    Vue.set(this, 'selects', {});
    Object.entries(this.map).forEach((it) => {
      if (it[1].type && it[1].type === 'select') {
        let val = [];
        if (this.getFieldConfig(it[0]) && this.getFieldConfig(it[0]).multiple) {
          val = [[]];
        }
        this.setSelectItems(it[0], val);
      }
    });

    return this;
  }

  initSliders() {
    Vue.set(this, 'sliders', {});
    Object.entries(this.map).forEach(([key, value]) => {
      if (value.type && value.type === 'slider') {
        this.setSlider(key, value);
      }
    });
    return this;
  }

  initInputs() {
    Vue.set(this, 'inputs', {});
    Object.entries(this.map).forEach(([key, value]) => {
      if (value.type && value.type === 'input') {
        this.setInput(key, value);
      }
    });
    return this;
  }

  initAutocompletes() {
    Vue.set(this, 'autocompletes', {});

    Object.entries(this.map).forEach((it) => {
      if (it[1].type && it[1].type === 'autocomplete') {
        let val = [];
        if (this.getFieldConfig(it[0]) && this.getFieldConfig(it[0]).multiple) {
          val = [[]];
        }
        this.setAutocompleteItems(it[0], val);
      }
    });

    return this;
  }

  initCheckboxes() {
    Vue.set(this, 'checkboxes', {});

    Object.entries(this.map).forEach((it) => {
      if (it[1].type && it[1].type === 'checkbox') {
        this.setCheckboxItems(it[0], []);
      }
    });

    return this;
  }

  initRadios() {
    Vue.set(this, 'radios', {});

    Object.entries(this.map).forEach((it) => {
      if (it[1].type && it[1].type === 'radio') {
        this.setRadioItems(it[0], it[1].fieldConfig.items);
      }
    });

    return this;
  }

  initCards() {
    Vue.set(this, 'cards', {});

    Object.entries(this.map).forEach((it) => {
      if (it[1].type && it[1].type === 'card') {
        this.setCardItems(it[0], it[1].fieldConfig.items);
      }
    });

    return this;
  }

  initMap(map) {
    Vue.set(this, 'map', map);

    return this;
  }

  initialize(map) {
    return this.initMap(map)
      .initSelects()
      .initAutocompletes()
      .initCheckboxes()
      .initRadios()
      .initCards()
      .initValues()
      .initInputs()
      .initSliders()
      .initErrors();
  }

  initSideEffects(sideEffects) {
    Vue.set(this, 'sideEffects', sideEffects);
  }

  checkSideEffects(fieldName, prevValue) {
    if (this.sideEffects && this.sideEffects[fieldName]) {
      this.sideEffects[fieldName](this, this.getValue(fieldName), prevValue);
    }
  }

  addToQueue(code) {
    if (!this.queue.includes(code)) {
      this.queue.unshift(code);
    }
    if (this.queue.length > this.queueCap) {
      this.queue.pop();
    }
  }

  getFieldQueue() {
    return this.queue || null;
  }

  constructor(modelName, map, sideEffects = null) {
    Vue.set(this, 'modelName', '');
    Vue.set(this, 'map', {});
    Vue.set(this, 'errors', {});
    Vue.set(this, 'autocompletes', {});
    Vue.set(this, 'selects', {});
    Vue.set(this, 'checkboxes', {});
    Vue.set(this, 'sliders', {});
    Vue.set(this, 'cards', {});
    Vue.set(this, 'radios', {});
    Vue.set(this, 'info', {});
    Vue.set(this, 'subscriptions', {});
    Vue.set(this, 'queue', []);
    Vue.set(this, 'queueCap', 2);
    Vue.set(this, 'inputs', {});

    this.setModelName(modelName)
      .initialize(map)
      .initSideEffects(sideEffects);
  }

  getInputProps(code, index = null) {
    const fieldConfig = this.getFieldConfig(code);
    return {
      key: `${code}${index !== null ? `_${index}` : ''}`,
      ...fieldConfig,
      value: this.getValue(code, index) || '',
    };
  }

  getRadioProps(code, index = null) {
    const fieldConfig = this.getFieldConfig(code);
    return {
      key: `${code}${index !== null ? `_${index}` : ''}`,
      ...fieldConfig,
      items: this.getRadioItems(code),
    };
  }

  getCardProps(code, index = null) {
    const fieldConfig = this.getFieldConfig(code);
    return {
      key: `${code}${index !== null ? `_${index}` : ''}`,
      ...fieldConfig,
      items: this.getCardItems(code),
    };
  }

  getAutocompleteProps(code, index = null) {
    const fieldConfig = this.getFieldConfig(code);
    return {
      key: `${code}${index !== null ? `_${index}` : ''}`,
      notHoverTitle: true,
      'title-top': this.getName(code),
      items: this.getAutocompleteItems(code, index),
      'value-field': fieldConfig.value,
      'text-field': fieldConfig.text,
      placeholder: fieldConfig.placeholder,
      required: !!fieldConfig.required,
      disabled: ((this.map.disabled && this.getValue('disabled')) || []).includes(code),
      value: this.getValue(code, index) || '',
      error: this.getError(code, index),
      updateValueOnMount: true,
      ignoreNullValuesForUpdate: true,
    };
  }

  triggerAutocompleteReload(code) {
    if (this.subscriptions[code]) {
      this.subscriptions[code]();
    }
  }

  subscribeAutocompleteReload(code, fn) {
    Vue.set(this.subscriptions, code, fn);
  }

  getAutocompleteItem(fieldName) {
    const fieldConfig = this.getFieldConfig(fieldName);
    const value = this.getValue(fieldName);

    return ((this.autocompletes[fieldName] || [])
      .find((it) => it[fieldConfig.value] === value) || {});
  }

  getAutocompleteItemTextValue(fieldName) {
    const fieldConfig = this.getFieldConfig(fieldName);
    const autocompleteItem = this.getAutocompleteItem(fieldName);

    if (Object.values(autocompleteItem).length === 0) {
      return null;
    }

    if (fieldConfig.search instanceof Array) {
      return fieldConfig.search.map((it) => autocompleteItem[it]).join(' ');
    }

    return autocompleteItem[fieldConfig.search || fieldConfig.text];
  }

  getSelectItem(fieldName) {
    const fieldConfig = this.getFieldConfig(fieldName);
    const value = this.getValue(fieldName);

    return ((this.selects[fieldName] || []).find((it) => it[fieldConfig.value] === value) || {});
  }

  getSelectItemTextValue(fieldName) {
    const fieldConfig = this.getFieldConfig(fieldName);
    return this.getSelectItem(fieldName)[fieldConfig.search || fieldConfig.text];
  }

  getSliderProps(code) {
    const fieldConfig = this.getFieldConfig(code);
    return {
      key: `${code}`,
      ...fieldConfig,
      value: this.getValue(code),
    };
  }

  getProps(code) {
    const { type } = this.map[code];
    switch (type) {
      case 'slider':
        return this.getSliderProps(code);
      case 'card':
        return this.getCardProps(code);
      case 'input':
        return this.getInputProps(code);
      case 'radio':
        return this.getRadioProps(code);
      default:
        return false;
    }
  }

  getSelectProps(code) {
    const fieldConfig = this.getFieldConfig(code);
    return {
      key: `${code}}`,
      title: this.getName(code),
      'item-value': fieldConfig.value,
      'item-text': fieldConfig.text,
      placeholder: fieldConfig.placeholder,
      required: !!fieldConfig.required,
      disabled: ((this.map.disabled && this.getValue('disabled')) || []).includes(code),
      items: this.getSelectItems(code),
      value: this.getValue(code),
      error: this.getError(code),
    };
  }

  // getProps(code) {
  //   return {
  //     title: this.getName(code),
  //     value: this.getValue(code),
  //   };
  // }

  getFieldsNames() {
    return Object.keys(this.map);
  }

  setVisible(fieldName, value) {
    this.map[fieldName].hidden = !value;
  }

  commit(info) {
    Vue.set(this, 'info', { ...this.info, ...info });
  }

  getInfo() {
    return this.info || {};
  }
}
