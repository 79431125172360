import Base from '../common/models/Base';

export default class Logistics extends Base {
  svgPrefix = 'cityfinale_svg__';

  constructor() {
    super(
      'Logistics',
      {
        bus: {
          type: 'input',
          fieldConfig: {
            title: 'Автобус:',
            description: 'Мин в день',

            border: true,
            type: 'number',
          },
        },
        trolleybus: {
          type: 'input',
          fieldConfig: {
            title: 'Троллейбус',

            border: true,
            type: 'number',
            description: 'Мин в день',
          },
        },
        minivan: {
          type: 'input',
          fieldConfig: {
            title: 'Маршрутка',
            border: true,
            type: 'number',
            description: 'Мин в день',
          },
        },
        metro: {
          type: 'input',
          fieldConfig: {
            title: 'Метро',
            border: true,
            type: 'number',
            description: 'Мин в день',
          },
        },
        tram: {
          type: 'input',
          fieldConfig: {
            title: 'Трамвай',
            border: true,
            type: 'number',
            description: 'Мин в день',
          },
        },
        train: {
          type: 'input',
          fieldConfig: {
            title: 'Электричка',
            border: true,
            type: 'number',
            description: 'Мин в день',
          },
        },
        taxi: {
          type: 'input',
          fieldConfig: {
            title: 'Такси',
            border: true,
            type: 'number',
            description: 'Мин в день',
          },
        },
        carSharing: {
          type: 'input',
          fieldConfig: {
            title: 'Каршеринг',
            border: true,
            type: 'number',
            description: 'Мин в день',
          },
        },
        electricScooter: {
          type: 'input',
          fieldConfig: {
            title: 'Электросамокат',
            border: true,
            type: 'number',
            description: 'Мин в день',
          },
        },
      },
      {
        bus: (_, value) => {
          const bus = document.querySelector(`#${this.svgPrefix}bus`);
          if (value) {
            return bus.classList.remove('disable');
          }
          return bus.classList.add('disable');
        },
        trolleybus: (_, value) => {
          const trolleybus = document.querySelector(`#${this.svgPrefix}trolleybus`);
          if (value) {
            return trolleybus.classList.remove('disable');
          }
          return trolleybus.classList.add('disable');
        },
        minivan: (_, value) => {
          const minivan = document.querySelector(`#${this.svgPrefix}minivan`);
          if (value) {
            return minivan.classList.remove('disable');
          }
          return minivan.classList.add('disable');
        },
        carSharing: (_, value) => {
          const carSharing = document.querySelectorAll(`#${this.svgPrefix}carsharing`);
          if (value) {
            return carSharing.forEach((item) => item.classList.remove('disable'));
          }
          return carSharing.forEach((item) => item.classList.add('disable'));
        },
        taxi: (_, value) => {
          const taxi = document.querySelector(`#${this.svgPrefix}taxi`);
          if (value) {
            return taxi.classList.remove('disable');
          }
          return taxi.classList.add('disable');
        },
        train: (_, value) => {
          const train = document.querySelector(`#${this.svgPrefix}train`);
          if (value) {
            return train.classList.remove('disable');
          }
          return train.classList.add('disable');
        },
        metro: (_, value) => {
          const metro = document.querySelector(`#${this.svgPrefix}metro`);
          if (value) {
            return metro.classList.remove('disable');
          }
          return metro.classList.add('disable');
        },
        tram: (_, value) => {
          const tram = document.querySelector(`#${this.svgPrefix}tram`);
          if (value) {
            return tram.classList.remove('disable');
          }
          return tram.classList.add('disable');
        },
        electricScooter: (_, value) => {
          const tram = document.querySelector(`#${this.svgPrefix}electricScooter`);
          if (value) {
            return tram.classList.remove('disable');
          }
          return tram.classList.add('disable');
        },
      },
    );
  }
}
